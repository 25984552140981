import React, { useEffect } from 'react'
import './edit-gallery.scss'
import { BiPhotoAlbum } from 'react-icons/bi'
// import { RiDeleteBin5Line } from "react-icons/ri";
import { FiFileText } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  getYearbookById,
  updateDataGallery,
  postImage,
  // deleteYearbook,
} from 'config/redux/action'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { useRef } from 'react'
import { Helmet } from 'react-helmet'

const EditGallery = () => {
  const { id } = useParams()
  const { yearbookById } = useSelector((state) => state.yearbookReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const dispatch = useDispatch()

  const inputRef = useRef()
  const onBtnClick = () => {
    inputRef.current.click()
  }

  useEffect(() => {
    dispatch(getYearbookById(id, 'isGallery'))
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...yearbookById,
    },
    validationSchema: yup.object({}),

    onSubmit: (values) => {
      const getStatus = (values) => {
        if (values === 'true') {
          return true
        } else if (values === 'false') {
          return false
        } else {
          return values
        }
      }
      dispatch(updateDataGallery(id, getStatus(values.status), values))
    },
  })

  const testUpload = async (image, index) => {
    const data = await postImage(image)
    formik.setValues({
      ...formik.values,
      images: [
        ...formik.values.images,
        {
          index: formik.values.images.length,
          id: 0,
          url: `https://dev.kawankelasku.com/api/${data?.path}`,
          status: true,
          isDelete: false,
        },
      ],
    })
  }

  const deleteImageArraybyItemIndex = (item) => {
    if (item.index) {
      formik.setValues({
        ...formik.values,
        images: formik.values.images.filter(
          (image) => image.index !== item.index,
        ),
      })
    } else {
      formik.setValues({
        ...formik.values,
        images: formik.values.images.map((image) => {
          if (image.id === item.id) {
            return {
              ...image,
              isDelete: true,
            }
          } else {
            return image
          }
        }),
      })
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | Gallery Edit</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/gallery/edit"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="gallery-edit">
        <h2>Gallery - Edit</h2>

        <div className="content-gallery">
          <div className="content-card">
            <div className="card-name">
              <p className="name">{yearbookById?.schoolName}</p>
              <p className="code">
                yr0{yearbookById?.id ? yearbookById?.id : id}
              </p>
            </div>
            <div className="card-photo">
              <div className="icon">
                <BiPhotoAlbum size="30" />
              </div>
              <p className="card-item">
                {yearbookById?.totalImages ? yearbookById.totalImages : '0'}{' '}
                Photos
              </p>
            </div>
            <div className="card-status">
              <div className="icon">
                <FiFileText size="30" />
              </div>
              <div>{yearbookById?.status ? 'Active' : 'NonActive'}</div>
            </div>
            {/* <div className='card-action'>
              <div
                className='button-delete'
                onClick={() => dispatch(deleteYearbook(id))}
              >
                <RiDeleteBin5Line size='25' />
              </div>
            </div> */}
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="details">
              <div className="form">
                <label>Status</label>
                <select
                  className="input-item"
                  onChange={(e) =>
                    formik.setFieldValue('status', e.target.value)
                  }
                  name="status"
                  onBlur={formik.handleBlur}
                >
                  <option hidden>Choose status</option>
                  <option value={true} selected={formik.values.status === true}>
                    Active
                  </option>
                  <option
                    value={false}
                    selected={formik.values.status === false}
                  >
                    NonActive
                  </option>
                </select>
              </div>
              <div className="images">
                <div className="row">
                  {formik?.values?.images?.map((item, index) => {
                    if (item.status && !item.isDelete) {
                      return (
                        <div
                          className="col-md-6 mb-2 px-1 image-wrapper"
                          key={`img-${index}`}
                        >
                          <img src={item.url} alt="" />
                          <div className="trash-wrapper">
                            <FaTrash
                              color="#fff"
                              fontSize={14}
                              onClick={() => deleteImageArraybyItemIndex(item)}
                            />
                          </div>
                        </div>
                      )
                    }
                  })}
                  <div className="col-md-6 mb-2 px-1">
                    <div className="upload-field" onClick={onBtnClick}>
                      <FaPlus />
                      <input
                        type="file"
                        ref={inputRef}
                        className="d-none"
                        multiple
                        onChange={(e) => testUpload(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-content">
                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() => (window.location = '/admin/gallery')}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditGallery
