import React, { useEffect } from "react";
import "./list.scss";
import { Helmet } from "react-helmet";
import { FaGraduationCap, FaPencilAlt } from "react-icons/fa";
import { BiPhotoAlbum } from "react-icons/bi";
import { FiFileText } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
// import { CgSortAz } from "react-icons/cg";
import { IoAddOutline, IoDocumentText, IoDownloadSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { deleteTeacher, getAllTeachers } from "config/redux/action";
import { Loading, Pagination } from "components";
import axios from "axios";
import { getYearbookDetail } from 'config/redux/action'

const TeacherList = () => {
  const { yearbookid } = useParams();
  const { teacher } = useSelector((state) => state.teacherReducer);
  const { paginate } = useSelector((state) => state.mainReducer);
  const { detailYearbook } = useSelector((state) => state.yearbookReducer)
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllTeachers(yearbookid, 1, 5));
    dispatch(getYearbookDetail(yearbookid))
  }, []);

  const toPage = (page) => {
    dispatch(getAllTeachers(yearbookid, page, paginate.size));
  };

  const tokenUser = localStorage.getItem("TOKEN");

  const downloadCsv = async () => {
    const config = {
      headers: { Authorization: `Bearer ${tokenUser}` },
    };
    const res = await axios.get(
      `https://dev.kawankelasku.com/api/yearbook/${yearbookid}/teacher/download`,
      config
    );
    window.open(`${res.data.data}`, "_blank");
  };

  if (!teacher) {
    return (
      <div className='teacher-list'>
        <div className='top-buttons d-flex align-items-center justify-content-between'>
          <div className='d-flex wrapper-title'>
            <h1>Teacher {detailYearbook && detailYearbook?.name}</h1>
          </div>
          <div className='buttons d-flex align-items-center'>
            <div className='btn-top download' onClick={() => downloadCsv()}>
              <IoDownloadSharp />
              <span>Download Excel</span>
            </div>
            <div
              className='btn-top upload'
              onClick={() =>
                history.push(`/admin/teacher/${yearbookid}/upload`)
              }>
              <IoDocumentText />
              <span>Upload Excel</span>
            </div>
            <div
              className='btn-top add-new'
              onClick={() =>
                history.push(`/admin/teacher/${yearbookid}/add`)
              }>
              <IoAddOutline />
              <span>Add New Teacher</span>
            </div>
          </div>
        </div>
        <h2>Tidak ada data..</h2>
      </div>
    )
  }
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin Panel | Teacher List</title>
        <link rel='canonical' href='https://kawankelasku.com/admin/teacher' />
        <meta name='description' content='placeholder' />
      </Helmet>

      {teacher.length === 0 ? (
        <Loading />
      ) : (
        <div className='teacher-list'>
          <div className='top-buttons d-flex align-items-center justify-content-between'>
            <div className='d-flex wrapper-title'>
              <h1>Teacher {detailYearbook && detailYearbook?.name}</h1>
              {/* <div className='sort-wrapper'>
              <CgSortAz color='#fff' fontSize={30} />
            </div> */}
            </div>
            <div className='buttons d-flex align-items-center'>
              <div className='btn-top download' onClick={() => downloadCsv()}>
                <IoDownloadSharp />
                <span>Download Excel</span>
              </div>
              <div
                className='btn-top upload'
                onClick={() =>
                  history.push(`/admin/teacher/${yearbookid}/upload`)
                }>
                <IoDocumentText />
                <span>Upload Excel</span>
              </div>
              <div
                className='btn-top add-new'
                onClick={() =>
                  history.push(`/admin/teacher/${yearbookid}/add`)
                }>
                <IoAddOutline />
                <span>Add New Teacher</span>
              </div>
            </div>
          </div>

          {teacher?.map((val, i) => (
            <div className='content-card' key={`data-${i}`}>
              <div className='card-name'>
                <p className='name'>{val.name ? val.name : "-"}</p>
                <p className='email'>{val.email}</p>
              </div>
              <div className='card-school'>
                <div className='icon'>
                  <FaGraduationCap size='35' />
                </div>
                <p className='card-item'>{val.subject ? val.subject : "-"}</p>
              </div>
              <div className='card-photo'>
                <div className='icon'>
                  <BiPhotoAlbum size='30' />
                </div>
                <p className='card-item'>{val.image ? "Yes" : "No"}</p>
              </div>
              <div className='card-status'>
                <div className='icon'>
                  <FiFileText size='30' />
                </div>
                <div>{val.status ? "Active" : "NonActive"}</div>
              </div>
              <div className='card-action'>
                <div
                  className='button-delete'
                  onClick={() => dispatch(deleteTeacher(yearbookid, val.id))}>
                  <RiDeleteBin5Line size='25' />
                </div>
                <div className='button-edit'>
                  <FaPencilAlt
                    size='25'
                    onClick={() =>
                      history.push(
                        `/admin/teacher/${yearbookid}/list/${val.id}/detail`
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ))}

          <Pagination onClick={toPage} page={paginate.count / paginate.size} />
        </div>
      )}
    </>
  );
};

export default TeacherList;
