import React, { useRef } from 'react'
import './add-teacher.scss'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { useParams } from 'react-router'
import { FormInput } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { postDataTeacher, postImage } from 'config/redux/action'
import * as yup from 'yup'

const AddTeacher = () => {
  const { yearbookid } = useParams()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const { isSubmitting } = useSelector((state) => state.mainReducer)

  const onBtnClick = () => {
    inputRef.current.click()
  }

  const testUpload = async (image, index) => {
    const data = await postImage(image)
    formik.setFieldValue(
      `image`,
      `https://dev.kawankelasku.com/api/${data?.path}`,
    )
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      instagram: '',
      facebook: '',
      motto: '',
      image: '',
      status: true,
    },
    validationSchema: yup.object({
      name: yup.string().required('Wajib menambahkan nama'),
      // email: yup
      //   .string()
      //   .email("Alamat email tidak valid")
      //   .required("Wajib menambahkan alamat email"),
      subject: yup.string().required('Wajib menambahkan subject'),
    }),

    onSubmit: (values) => {
      dispatch(postDataTeacher(yearbookid, values))
    },
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | Teacher Add</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/teacher/add"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="teacher-add">
        <h2>Teacher - Add</h2>

        <div className="content-teacher">
          <form onSubmit={formik.handleSubmit}>
            <div className="details">
              <FormInput
                label="Name"
                errorMessage={formik.errors.name}
                error={formik.errors.name && formik.touched.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                type="text"
                name="name"
                placeholder="Input name"
              />
              <FormInput
                label="Email"
                errorMessage={formik.errors.email}
                error={formik.errors.email && formik.touched.email}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                name="email"
                placeholder="Input email"
              />
              <FormInput
                label="Subject"
                errorMessage={formik.errors.subject}
                error={formik.errors.subject && formik.touched.subject}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.subject}
                type="text"
                name="subject"
                placeholder="Input subject"
              />
              <FormInput
                label="Instagram"
                errorMessage={formik.errors.instagram}
                error={formik.errors.instagram && formik.touched.instagram}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.instagram}
                type="text"
                name="instagram"
                placeholder="Input instagram"
              />
              <FormInput
                label="Facebook"
                errorMessage={formik.errors.facebook}
                error={formik.errors.facebook && formik.touched.facebook}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.facebook}
                type="text"
                name="facebook"
                placeholder="Input facebook"
              />
              <FormInput
                label="Motto"
                errorMessage={formik.errors.motto}
                error={formik.errors.motto && formik.touched.motto}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.motto}
                type="text"
                name="motto"
                placeholder="Input motto"
              />

              <label>Photo Profile</label>
              <div>
                {formik.values.image ? (
                  <div className="input-image">
                    <div
                      className="trash-icon"
                      onClick={() => formik.setFieldValue(`image`, '')}
                    >
                      <FaTrash color="#fff" fontSize={14} />
                    </div>
                    <img
                      className="preview-content"
                      src={formik.values.image}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    className="input-image d-flex justify-content-center"
                    style={{ cursor: 'pointer' }}
                    onClick={onBtnClick}
                  >
                    <FaPlus />
                  </div>
                )}
                <input
                  type="file"
                  ref={inputRef}
                  className="d-none"
                  name="image"
                  onBlur={formik.handleBlur}
                  onChange={(e) => testUpload(e.target.files[0])}
                />
              </div>

              <div className="button-content">
                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() =>
                        (window.location = `/admin/teacher/${yearbookid}/list`)
                      }
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddTeacher
