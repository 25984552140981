import React, { useState, useEffect, useRef } from 'react'
import './edit-yearbook.scss'
import Input from 'components/atoms/form-input'
import { useFormik } from 'formik'
import { FormInput, FormSelect } from 'components'
import { FaFilePdf, FaGraduationCap, FaPlus, FaTrash } from 'react-icons/fa'
import { MdContactPage } from 'react-icons/md'
// import { RiDeleteBin5Line } from "react-icons/ri";
import { FiFileText } from 'react-icons/fi'
import { BsDot } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  postFile,
  getAllSchools,
  getLevelByShoolId,
  getYearbookDetail,
  updateDataYearBook,
  // updateStatusYearBook,
} from 'config/redux/action'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'

const EditYearbook = () => {
  const { id } = useParams()
  const { detailYearbook } = useSelector((state) => state.yearbookReducer)
  const { schools, schoolLevels } = useSelector((state) => state.mainReducer)
  const { schoolLevelDetails } = useSelector((state) => state.adminReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const [dataBySchool, setDataBySchool] = useState(false)
  const inputRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getYearbookDetail(id))
    dispatch(getAllSchools(1, 1000))
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...detailYearbook,
    },
    validationSchema: yup.object({
      name: yup.string().required('Wajib memasukkan nama'),
      schoolName: yup.string().required('Wajib memilih sekolah'),
      levelName: yup.string().required('Wajib memilih level'),
      year: yup.string().required('Wajib memilih tahun'),
    }),

    onSubmit: (values) => {
      dispatch(updateDataYearBook(id, values))
    },
  })

  const onBtnClick = () => {
    inputRef.current.click()
  }

  const testUpload = async (item, index) => {
    dispatch({
      type: 'SET_ISSUBMITTING',
      payload: true,
    })
    const data = await postFile(item)
    formik.setFieldValue(`originalname`, data?.originalname)
    formik.setFieldValue(
      'url',
      `https://dev.kawankelasku.com/api/${data?.path}`,
    )
    if (data) {
      dispatch({
        type: 'SET_ISSUBMITTING',
        payload: false,
      })
    }
  }

  const onHandleDelete = () => {
    formik.setFieldValue(`originalname`, '')
    formik.setFieldValue(`url`, '')
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | Yearbook Edit</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/yearbook/edit"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="yearbook-edit">
        <h2>Yearbook - Edit</h2>

        <div className="content-yearbook">
          <div className="content-card">
            <div className="card-name">
              <p className="name">
                {detailYearbook && detailYearbook.name
                  ? detailYearbook.name
                  : '-'}
              </p>
              <p className="code">
                sch{detailYearbook?.id} <BsDot />{' '}
                <span className="year">{detailYearbook?.year}</span>
              </p>
            </div>
            <div className="card-school">
              <div className="icon">
                <FaGraduationCap size="35" />
              </div>
              <p className="card-item">{detailYearbook?.schoolName}</p>
            </div>
            <div className="card-level">
              <div className="icon">
                <MdContactPage size="30" />
              </div>
              <p className="card-item">{detailYearbook?.levelName}</p>
            </div>
            <div className="card-status">
              <div className="icon">
                <FiFileText size="30" />
              </div>
              <div>
                {detailYearbook && detailYearbook.status
                  ? 'Active'
                  : 'NonActive'}
              </div>
            </div>
            {/* <div className='card-action'>
              <div
                className='button-delete'
                onClick={() => dispatch(updateStatusYearBook(id, { name: detailYearbook?.name, status: false }))}
              >
                <RiDeleteBin5Line size='25' />
              </div>
            </div> */}
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="details">
              <Input
                label="School Code"
                value={`sch${detailYearbook?.id}`}
                disabled
              />
              <FormInput
                label="Yearbook"
                errorMessage={formik.errors.name}
                error={formik.errors.name && formik.touched.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                type="text"
                name="name"
                placeholder="Input yearbook name"
              />
              <FormSelect
                label="School"
                data={schools.filter(item => item.status)}
                placeholder="Choose your school"
                onChange={(e) => {
                  setDataBySchool(true)
                  formik.setFieldValue(
                    'schoolId',
                    parseInt(e.target[e.target.selectedIndex].id),
                  )
                  formik.setFieldValue('schoolName', e.target.value)
                  dispatch(
                    getLevelByShoolId(e.target[e.target.selectedIndex].id),
                  )
                }}
                name="schoolName"
                value={formik.values.schoolName}
                onBlur={formik.handleBlur}
              />
              <FormSelect
                label="Level"
                data={dataBySchool ? schoolLevels : schoolLevelDetails}
                placeholder="Choose your level"
                onChange={(e) => {
                  formik.setFieldValue(
                    'levelId',
                    parseInt(e.target[e.target.selectedIndex].id),
                  )
                  formik.setFieldValue('levelName', e.target.value)
                }}
                name="level"
                value={formik.values.levelName}
                onBlur={formik.handleBlur}
              />
              <FormSelect
                label="Year"
                data={[
                  { id: 1, Name: '2006' },
                  { id: 2, Name: '2007' },
                  { id: 3, Name: '2008' },
                  { id: 4, Name: '2009' },
                  { id: 5, Name: '2010' },
                  { id: 6, Name: '2011' },
                  { id: 7, Name: '2012' },
                  { id: 8, Name: '2013' },
                  { id: 9, Name: '2014' },
                  { id: 10, Name: '2015' },
                  { id: 11, Name: '2016' },
                  { id: 12, Name: '2017' },
                  { id: 13, Name: '2018' },
                  { id: 14, Name: '2019' },
                  { id: 15, Name: '2020' },
                  { id: 16, Name: '2021' },
                  { id: 17, Name: '2022' },
                  { id: 18, Name: '2023' },
                ]}
                placeholder="Choose your year"
                onChange={formik.handleChange}
                name="year"
                value={formik.values.year}
                onBlur={formik.handleBlur}
              />

              <div className="form">
                <label>Status</label>
                <select
                  className="input-item"
                  onChange={(e) =>
                    formik.setFieldValue('status', e.target.value)
                  }
                  name="status"
                  onBlur={formik.handleBlur}
                >
                  <option hidden>Choose status</option>
                  <option value={true} selected={formik.values.status === true}>
                    Active
                  </option>
                  <option
                    value={false}
                    selected={formik.values.status === false}
                  >
                    NonActive
                  </option>
                </select>
              </div>

              <label>Input File PDF</label>
              <div>
                {formik.values.url ? (
                  <div className="input-pdf">
                    <div
                      className="trash-icon"
                      onClick={() => onHandleDelete()}
                    >
                      <FaTrash color="#fff" fontSize={14} />
                    </div>
                    <label className="preview-content">
                      <FaFilePdf color="red" />{' '}
                      {formik.values.originalname
                        ? formik.values.originalname
                        : `${formik.values.name}.pdf`}
                    </label>
                  </div>
                ) : (
                  <div className="input-pdf no-file" onClick={onBtnClick}>
                    <FaPlus />
                  </div>
                )}
                <input
                  name="url"
                  type="file"
                  className="d-none"
                  accept="application/pdf"
                  ref={inputRef}
                  onBlur={formik.handleBlur}
                  onChange={(e) => testUpload(e.target.files[0])}
                />
              </div>

              <div className="button-content">
                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() => (window.location = '/admin/yearbook')}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditYearbook
