import React, { useEffect, useRef } from 'react'
import './edit-school.scss'
import { FaPlus } from 'react-icons/fa'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FiFileText } from 'react-icons/fi'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { FormInput, FormTextarea } from 'components'
import { useFormik } from 'formik'
import {
  // deleteSchool,
  getSchoolById,
  postImage,
  updateDataSchool,
} from 'config/redux/action'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'

const EditSchool = () => {
  const { id } = useParams()
  const { detailSchool, isSubmitting } = useSelector(
    (state) => state.mainReducer,
  )
  const inputRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSchoolById(id))
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...detailSchool,
    },
    validationSchema: yup.object({
      name: yup.string().required('Masukkan nama sekolah'),
      address: yup.string().required('Masukkan alamat sekolah'),
      phoneNumber: yup.number().required('Masukkan nomor handphone'),
    }),

    onSubmit: (values) => {
      dispatch(updateDataSchool(id, values))
    },
  })

  // LEVEL
  const addLevelForm = () => {
    const newLevel = {
      id: 0,
      Status: true,
      index: formik.values.levels.length + 1,
      name: '',
      Name: '',
    }
    formik.setFieldValue('levels', [...formik.values.levels, newLevel])
  }

  const deleteLevelField = (item) => {
    if (item.index) {
      formik.setValues({
        ...formik.values,
        levels: formik.values.levels.filter((lvl) => lvl.index !== item.index),
      })
    } else {
      formik.setValues({
        ...formik.values,
        levels: formik.values.levels.map((lvl) => {
          if (lvl.id === item.id) {
            return {
              ...lvl,
              Status: false,
            }
          } else {
            return lvl
          }
        }),
      })
    }
  }

  // CONTENT
  const addContentForm = () => {
    const newLevel = {
      id: 0,
      index: formik.values.contents.length + 1,
      Title: '',
      Status: true,
      Description: '',
      Url: '',
    }
    formik.setFieldValue('contents', [...formik.values.contents, newLevel])
  }

  const deleteContentField = (item) => {
    if (item.index) {
      formik.setValues({
        ...formik.values,
        contents: formik.values.contents.filter(
          (content) => content.index !== item.index,
        ),
      })
    } else {
      formik.setValues({
        ...formik.values,
        contents: formik.values.contents.map((content) => {
          if (content.id === item.id) {
            return {
              ...content,
              Status: false,
            }
          } else {
            return content
          }
        }),
      })
    }
  }

  const onBtnClick = () => {
    inputRef.current.click()
  }

  const testUpload = async (image, index) => {
    const data = await postImage(image)
    formik.setFieldValue(
      `contents[${index}].Url`,
      `https://dev.kawankelasku.com/api/${data?.path}`,
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | School Edit</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/school/edit"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="edit-school">
        <h2>School - Edit</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="content-school">
            <div className="content-card">
              <div className="card-name">
                <p className="name">{detailSchool?.name}</p>
                <p className="email">sch{detailSchool?.id}</p>
              </div>
              <div className="card-address">
                <div className="icon">
                  <FaMapMarkerAlt size="30" />
                </div>
                <p className="card-item">{detailSchool?.address}</p>
              </div>
              <div className="card-status">
                <div className="icon">
                  <FiFileText size="30" />
                </div>
                <div>{detailSchool?.status ? 'Active' : 'NonActive'}</div>
              </div>
              {/* <div className='card-action'>
                <div
                  className='button-delete'
                  onClick={() => dispatch(deleteSchool(id))}
                >
                  <RiDeleteBin5Line size='25' />
                </div>
              </div> */}
            </div>

            <div className="details">
              <FormInput
                label="School Code"
                disabled
                value={`sch${detailSchool?.id}`}
              />
              <FormInput
                label="Phone Number"
                errorMessage={formik.errors.phoneNumber}
                error={formik.errors.phoneNumber && formik.touched.phoneNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                name="phoneNumber"
                placeholder="Input Phone Number"
              />
              <FormInput
                label="School Name"
                errorMessage={formik.errors.name}
                error={formik.errors.name && formik.touched.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                placeholder="Input School Name"
              />
              <FormInput
                label="Address"
                errorMessage={formik.errors.address}
                error={formik.errors.address && formik.touched.address}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                onChange={formik.handleChange}
                name="address"
                placeholder="Input School Address"
              />

              <div className="form">
                <label>Status</label>
                <select
                  className="input-item"
                  onChange={(e) =>
                    formik.setFieldValue('status', e.target.value)
                  }
                  name="status"
                  onBlur={formik.handleBlur}
                >
                  <option hidden>Choose status</option>
                  <option value={true} selected={formik.values.status === true}>
                    Active
                  </option>
                  <option
                    value={false}
                    selected={formik.values.status === false}
                  >
                    NonActive
                  </option>
                </select>
              </div>
              <div className="levels">
                <label>Level</label>
                {formik?.values?.levels?.map((level, index) => {
                  if (level.Status) {
                    return (
                      <div
                        className="items d-flex align-items-center justify-content-between"
                        key={`lvl-${index}`}
                      >
                        <div
                          className="level-name"
                          style={{ flex: 1, marginRight: 10 }}
                        >
                          <FormInput
                            noLabel
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={
                              `levels[${index}].name`
                                ? `levels[${index}].name`
                                : `levels[${index}].Name`
                            }
                            value={level.name ? level.name : level.Name}
                            placeholder="Input level"
                          />
                        </div>
                        <RiDeleteBin5Line
                          size="25"
                          style={{ marginBottom: 16, cursor: 'pointer' }}
                          onClick={() => deleteLevelField(level)}
                        />
                      </div>
                    )
                  }
                })}
                <div className="add-new-level" onClick={addLevelForm}>
                  Add New Level
                </div>
              </div>

              <div className="profile-content">
                <label>Profile Content</label>
                <div className="cards">
                  {formik?.values?.contents?.map((content, index) => {
                    if (content.Status) {
                      return (
                        <div
                          className="content-card-school"
                          key={`pc-${index}`}
                        >
                          {formik?.values?.contents[index]?.Url ? (
                            <img
                              className="preview-content"
                              src={formik.values.contents[index].Url}
                              alt=""
                            />
                          ) : (
                            <div className="input-plus" onClick={onBtnClick}>
                              <FaPlus />
                            </div>
                          )}
                          <input
                            type="file"
                            ref={inputRef}
                            className="d-none"
                            name={`contents[${index}].Url`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onChange={(e) =>
                              testUpload(e.target.files[0], index)
                            }
                          />
                          <div className="details mt-2">
                            <div className="item">
                              <FormInput
                                label="Title"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={`contents[${index}].Title`}
                                value={content.Title}
                                placeholder="Input title"
                              />
                            </div>
                            <div className="item">
                              <FormTextarea
                                label="Description"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={`contents[${index}].Description`}
                                value={content.Description}
                                placeholder="Input description"
                              />
                            </div>

                            <div className="icon-trash">
                              <RiDeleteBin5Line
                                size="20"
                                color="#0F75BC"
                                onClick={() => deleteContentField(content)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>

                <div className="add-new-content" onClick={addContentForm}>
                  Add New Content
                </div>

                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() => (window.location = '/admin/school')}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditSchool
