import React, { useEffect } from 'react'
import './list.scss'
import { Helmet } from 'react-helmet'
import { FaGraduationCap, FaPencilAlt } from 'react-icons/fa'
import { BiPhotoAlbum } from 'react-icons/bi'
import { FiFileText } from 'react-icons/fi'
import { RiDeleteBin5Line } from 'react-icons/ri'
// import { CgSortAz } from "react-icons/cg";
import { IoAddOutline, IoDocumentText, IoDownloadSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getAllAlumnies, deleteAlumni } from 'config/redux/action/alumniAction'
import { Loading, Pagination } from 'components'
import axios from 'axios'
import { getYearbookDetail } from 'config/redux/action'

const AlumniList = () => {
  const { yearbookid } = useParams()
  const { alumni } = useSelector((state) => state.alumniReducer)
  const { paginate } = useSelector((state) => state.mainReducer)
  const { detailYearbook } = useSelector((state) => state.yearbookReducer)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getAllAlumnies(yearbookid, 1, 5))
    dispatch(getYearbookDetail(yearbookid))
  }, [])

  const toPage = (page) => {
    dispatch(getAllAlumnies(yearbookid, page, paginate.size))
  }

  const tokenUser = localStorage.getItem('TOKEN')

  const downloadCsv = async () => {
    const config = {
      headers: { Authorization: `Bearer ${tokenUser}` },
    }
    const res = await axios.get(
      `https://dev.kawankelasku.com/api/yearbook/${yearbookid}/alumni/download`,
      config,
    )
    window.open(`${res.data.data}`, '_blank')
  }

  if (!alumni) {
    return (
      <div className="alumni-list">
        <div className="top-buttons d-flex align-items-center justify-content-between">
          <div className="d-flex wrapper-title">
            <h1>Alumni {detailYearbook && detailYearbook?.name}</h1>
          </div>
          <div className="buttons d-flex align-items-center">
            <div className="btn-top download" onClick={() => downloadCsv()}>
              <IoDownloadSharp />
              <span>Download Excel</span>
            </div>
            <div
              className="btn-top upload"
              onClick={() => history.push(`/admin/alumni/${yearbookid}/upload`)}
            >
              <IoDocumentText />
              <span>Upload Excel</span>
            </div>
            <div
              className="btn-top add-new"
              onClick={() => history.push(`/admin/alumni/${yearbookid}/add`)}
            >
              <IoAddOutline />
              <span>Add New Alumni</span>
            </div>
          </div>
        </div>
        <h2>Tidak ada data..</h2>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | Alumni List</title>
        <link rel="canonical" href="https://kawankelasku.com/admin/list" />
        <meta name="description" content="placeholder" />
      </Helmet>
      {alumni.length === 0 ? (
        <Loading />
      ) : (
        <div className="alumni-list">
          <div className="top-buttons d-flex align-items-center justify-content-between">
            <div className="d-flex wrapper-title">
              <h1>Alumni {detailYearbook && detailYearbook?.name}</h1>
              {/* <div className='sort-wrapper'>
              <CgSortAz color='#fff' fontSize={30} />
            </div> */}
            </div>
            <div className="buttons d-flex align-items-center">
              <div className="btn-top download" onClick={() => downloadCsv()}>
                <IoDownloadSharp />
                <span>Download Excel</span>
              </div>
              <div
                className="btn-top upload"
                onClick={() =>
                  history.push(`/admin/alumni/${yearbookid}/upload`)
                }
              >
                <IoDocumentText />
                <span>Upload Excel</span>
              </div>
              <div
                className="btn-top add-new"
                onClick={() => history.push(`/admin/alumni/${yearbookid}/add`)}
              >
                <IoAddOutline />
                <span>Add New Alumni</span>
              </div>
            </div>
          </div>

          {alumni?.map((val, i) => (
            <div className="content-card" key={`data-${i}`}>
              <div className="card-name">
                <p className="name">{val.name ? val.name : '-'}</p>
                <p className="email">{val.email}</p>
              </div>
              <div className="card-school">
                <div className="icon">
                  <FaGraduationCap size="35" />
                </div>
                <p className="card-item">{val.class ? val.class : '-'}</p>
              </div>
              <div className="card-photo">
                <div className="icon">
                  <BiPhotoAlbum size="30" />
                </div>
                <p className="card-item">{val.image ? 'Yes' : 'No'}</p>
              </div>
              <div className="card-status">
                <div className="icon">
                  <FiFileText size="30" />
                </div>
                <div>{val.status ? 'Active' : 'NonActive'}</div>
              </div>
              <div className="card-action">
                <div
                  className="button-delete"
                  onClick={() => dispatch(deleteAlumni(yearbookid, val.id))}
                >
                  <RiDeleteBin5Line size="25" />
                </div>
                <div className="button-edit">
                  <FaPencilAlt
                    size="25"
                    onClick={() =>
                      history.push(
                        `/admin/alumni/${yearbookid}/list/${val.id}/detail`,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ))}

          <Pagination onClick={toPage} page={paginate.count / paginate.size} />
        </div>
      )}
    </>
  )
}

export default AlumniList
