import React, { useEffect, useRef } from 'react'
import './edit-alumni.scss'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { FormInput } from 'components'
import { BiPhotoAlbum } from 'react-icons/bi'
import { FaGraduationCap, FaPlus, FaTrash } from 'react-icons/fa'
import { FiFileText } from 'react-icons/fi'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  deleteAlumni,
  getAlumniDetail,
  postImage,
  updateDataAlumni,
} from 'config/redux/action'
import * as yup from 'yup'

const EditAlumni = () => {
  const { yearbookid, id } = useParams()
  const { detailAlumni } = useSelector((state) => state.alumniReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const dispatch = useDispatch()
  const inputRef = useRef()

  useEffect(() => {
    dispatch(getAlumniDetail(yearbookid, id))
  }, [])

  const onBtnClick = () => {
    inputRef.current.click()
  }

  const testUpload = async (image, index) => {
    const data = await postImage(image)
    formik.setFieldValue(
      `image`,
      `https://dev.kawankelasku.com/api/${data?.path}`,
    )
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...detailAlumni,
    },
    validationSchema: yup.object({
      name: yup.string().required('Wajib memasukkan nama'),
      // email: yup
      //   .string()
      //   .email("Alamat email tidak valid")
      //   .required("Wajib menambahkan alamat email"),
      class: yup.string().required('Wajib memasukkan class'),
    }),

    onSubmit: (values) => {
      dispatch(updateDataAlumni(yearbookid, id, values))
    },
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | Alumni Edit</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/alumni/edit"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="alumni-edit">
        <h2>Alumni - Edit</h2>

        <div className="content-alumni">
          <div className="content-card">
            <div className="card-name">
              <p className="name">{detailAlumni && detailAlumni.name}</p>
              <p className="email">{detailAlumni && detailAlumni.email}</p>
            </div>
            <div className="card-school">
              <div className="icon">
                <FaGraduationCap size="35" />
              </div>
              <p className="card-item">{detailAlumni && detailAlumni.class}</p>
            </div>
            <div className="card-photo">
              <div className="icon">
                <BiPhotoAlbum size="30" />
              </div>
              <p className="card-item">
                {detailAlumni && detailAlumni.image ? 'Yes' : 'No'}
              </p>
            </div>
            <div className="card-status">
              <div className="icon">
                <FiFileText size="30" />
              </div>
              <div>
                {detailAlumni && detailAlumni.status ? 'Active' : 'NonActive'}
              </div>
            </div>
            <div className="card-action">
              <div
                className="button-delete"
                onClick={() => dispatch(deleteAlumni(yearbookid, id))}
              >
                <RiDeleteBin5Line size="25" />
              </div>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="details">
              <FormInput
                label="Name"
                errorMessage={formik.errors.name}
                error={formik.errors.name && formik.touched.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                type="text"
                name="name"
                placeholder="Input name"
              />
              <FormInput
                label="Email"
                errorMessage={formik.errors.email}
                error={formik.errors.email && formik.touched.email}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                name="email"
                placeholder="Input email"
              />
              <FormInput
                label="Class"
                errorMessage={formik.errors.class}
                error={formik.errors.class && formik.touched.class}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.class}
                type="text"
                name="class"
                placeholder="Input class"
              />
              <FormInput
                label="Instagram"
                errorMessage={formik.errors.instagram}
                error={formik.errors.instagram && formik.touched.instagram}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.instagram}
                type="text"
                name="instagram"
                placeholder="Input instagram"
              />
              <FormInput
                label="Facebook"
                errorMessage={formik.errors.facebook}
                error={formik.errors.facebook && formik.touched.facebook}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.facebook}
                type="text"
                name="facebook"
                placeholder="Input facebook"
              />
              <FormInput
                label="Motto"
                errorMessage={formik.errors.motto}
                error={formik.errors.motto && formik.touched.motto}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.motto}
                type="text"
                name="motto"
                placeholder="Input motto"
              />

              <label>Photo Profile</label>
              <div>
                {formik.values.image ? (
                  <div className="input-image">
                    <div
                      className="trash-icon"
                      onClick={() => formik.setFieldValue(`image`, '')}
                    >
                      <FaTrash color="#fff" fontSize={14} />
                    </div>
                    <img
                      className="preview-content"
                      src={formik.values.image}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    className="input-image d-flex justify-content-center"
                    style={{ cursor: 'pointer' }}
                    onClick={onBtnClick}
                  >
                    <FaPlus />
                  </div>
                )}
                <input
                  type="file"
                  ref={inputRef}
                  className="d-none"
                  name="image"
                  onBlur={formik.handleBlur}
                  onChange={(e) => testUpload(e.target.files[0])}
                />
              </div>

              <div className="button-content">
                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() =>
                        (window.location = `/admin/alumni/${yearbookid}/list`)
                      }
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditAlumni
